import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"

import "../index.scss"

import ImgLogo from "../../images/logo-tanecni-zlonin.svg"

import "../normalize.css"

import Bloglist from "../../components/bloglist/bloglist"
import Footer from "../../components/footer/footer"

const Article = () => {
	return (

		<Layout>
			<Seo title="Poděkování účastníkům tančírny" />

			<div className="container container--article mb3">

				<Link to="/" data-sal="fade" data-sal-duration="1000">
					<img src={ImgLogo} alt="Taneční Zlonín logo" className="tz-miniheader__logo" />
				</Link>

				<h1 className="tz-miniheader__h1" data-sal="fade" data-sal-duration="1000">
					Poděkování účastníkům tančírny
				</h1>

				<p data-sal="fade" data-sal-duration="1000">Děkujeme všem kdo včera přišel na&nbsp;tančírnu 💃🕺 a&nbsp;tím přispěl na&nbsp;pomoc Ukrajině!</p>

				<p data-sal="fade" data-sal-duration="1000">Vstupné v&nbsp;hodnotě 1500,- už je na&nbsp;transparentním účtě pro&nbsp;finanční pomoc ubytovaným rodinám ve&nbsp;Zloníně.</p>

				<p data-sal="fade" data-sal-duration="1000">Děkujeme❣️<br />Irina &amp; Jarda</p>

			</div>

			<Bloglist></Bloglist>
			<Footer></Footer>

		</Layout>

	)
}

export default Article